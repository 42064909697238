import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'reactstrap';
import '../../assets/css/developers.css';
import { ToastContainer, toast } from 'react-toastify';
import CarbonNeutralWidgetPreview from '../../components/CarbonNeutralWidgetPreview'; // Import CarbonNeutralWidgetPreview
import EventCarbonCalculatorPreview from '../../components/EventCarbonCalculatorPreview'; // Import EventCarbonCalculatorPreview

const Widgets = () => {
    return (
        <Container className="dashboard">
            <Row className="mb-4">
                <Col>
                    <h1 style={{ fontWeight: 'bold' }}>Embedable Widgets</h1>
                </Col>
            </Row>
            {/* Widget Integration Section */}
            <Row style={{marginTop: '20px'}}>
                <Col>
                    <Card className="p-4" style={{ borderRadius: '15px', borderColor: 'white' }}>
                        <h4>Embed the Carbon-Neutral Widget</h4>
                        <p>Copy and paste the following code snippet into your website to add the widget.</p>
                        <pre className="embed-code">
                            {`<div class="carbon-neutral-widget" data-km="400" data-currency="SAR"></div>\n<script type="text/javascript" src="https://public-server-n3bj.onrender.com/scripts/w/carbon-neutral-widget.min.js"></script>`}
                        </pre>
                        <CarbonNeutralWidgetPreview km="400" price="20.00" currency="SAR" />
                    </Card>
                </Col>
            </Row>

            {/* Event Carbon Calculator Integration Section */}
            <Row style={{marginTop: '20px'}}>
                <Col>
                    <Card className="p-4" style={{ borderRadius: '15px', borderColor: 'white' }}>
                        <h4>Embed the Event Carbon Calculator Widget</h4>
                        <p>Copy and paste the following code snippet into your event or conference website to allow attendees to calculate their carbon emissions.</p>
                        <pre className="embed-code">
                            {`<div class="event-carbon-calculator-widget"></div>\n<script type="text/javascript" src="https://public-server-n3bj.onrender.com/scripts/e/event-carbon-calculator.min.js"></script>`}
                        </pre>
                        <EventCarbonCalculatorPreview />
                    </Card>
                </Col>
            </Row>

            <ToastContainer />
        </Container>
    );
};

export default Widgets;
