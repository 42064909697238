import React from 'react';
import { Col, Container, Row } from "reactstrap";
import impactImg from '../assets/images/impact-section.png';

const ImpactSection = () => {
    return (
        <section style={{ display: 'flex', padding: '40px', backgroundColor: '#8ae5c3', alignItems: 'center' }}>
            <div style={{ flex: 1, marginRight: '40px' }}>
                <img 
                    src={impactImg} 
                    alt="Impact page on tablet" 
                    style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }} 
                />
            </div>
            <div style={{ flex: 1 }}>
                <h2 style={{ fontSize: '36px', marginBottom: '20px' }}>
                Share Your Impact Instantly and Authentically
                </h2>
                <p style={{ fontSize: '18px', marginBottom: '20px', lineHeight: '1.6' }}>
                Effortlessly showcase your impact in real time with our standalone impact page and embeddable widget. Leverage our video and image assets to tell your story in your unique voice.
                </p>
                <p style={{ fontSize: '18px', marginBottom: '40px', lineHeight: '1.6' }}>
                Communicate your climate initiatives in clear, stakeholder-friendly language to make a lasting impression.
                </p>
                {/*<button 
                    className="btn btn-primary"
                >
                    Sample impact page
                </button>*/}
            </div>
        </section>
    );
};

export default ImpactSection;
