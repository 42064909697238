import React, { useEffect, useState } from 'react';
import { Table, Container, Spinner } from 'reactstrap';
import http from '../../../frameworks/basic-rest/http';  // Your http request module
import { API_ENDPOINTS } from '../../../frameworks/basic-rest/api-endpoints';  // Your API endpoints module

const AuditPage = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAuditLogs = async () => {
      try {
        const response = await http.get(API_ENDPOINTS.AUDIT_LOGS);  // Assuming AUDIT_LOGS is the correct endpoint
        setAuditLogs(response.data.logs);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching audit logs:', err);
        setError('Failed to fetch audit logs.');
        setLoading(false);
      }
    };

    fetchAuditLogs();
  }, []);

  if (loading) return <Container><Spinner /></Container>;
  if (error) return <Container><p>{error}</p></Container>;

  return (
    <Container className="dashboard">
      <h2>Audit Logs</h2>
      <Table striped>
        <thead>
          <tr>
            <th>Action</th>
            <th>Credits Changed</th>
            <th>Performed By</th>
            <th>Credit Batch</th>
            <th>Order</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {auditLogs.map((log) => (
            <tr key={log._id}>
              <td>{log.action}</td>
              <td>{log.creditsChanged}</td>
              <td>{log.performedBy || 'System'}</td>
              <td>{log.creditBatchId ? log.creditBatchId.batchNumber : 'N/A'}</td>
              <td>{log.orderId ? log.orderId.orderNumber : 'N/A'}</td>
              <td>{new Date(log.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AuditPage;
