import React from 'react';
import { Container, Row } from "reactstrap";
import ProductShowcase from './ProductShowcase';
import estimateImpactImg from '../assets/images/estimate-impact.png';
import orderApiIntegrationImg from '../assets/images/order-api.png';
import eventCalcImg from '../assets/images/event-calc.png';

const ProductsSection = () => {
    return (
        <React.Fragment>
            <section className="section" id="portfolio">
                <Container fluid={true}>
                    <div className="header-title text-center">
                        <h3>Buying carbon credits doesn’t have to be hard</h3>
                        <div className="title-border mt-3"></div>
                        <p className="title-desc text-muted mt-3">
                        Here are some ways you can seamlessly integrate your system with c-mass to estimate emissions and offset your carbon impact.
                        </p>
                    </div>
                    <Row className="mt-5 pt-2">
                        <ProductShowcase
                            title="Estimate impact"
                            description="c-mass helps you accurately estimate the carbon impact of your shipping, bus, ridesharing, delivery, or other fleet operations."
                            imgSrc={estimateImpactImg}
                            btnText="Talk to Sales"
                            btnLink="#contact"
                            num={1}
                        />
                        <ProductShowcase
                            title="Integrate with your product"
                            description="Integrate carbon credits seamlessly into every transaction using c-mass’s user-friendly API, empowering your customers to make a positive impact with every purchase."
                            imgSrc={orderApiIntegrationImg}
                            btnText="API integration"
                            btnLink="#"
                            num={2}
                        />
                        <ProductShowcase
                            title="Event Carbon Calculator"
                            description="Integrate an event carbon calculator into your event's website using our lightweight API, enabling your attendees to take meaningful climate action."
                            imgSrc={eventCalcImg}
                            btnText="Event Carbon Calculator"
                            btnLink="#"
                            num={3}
                        />
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default ProductsSection;
