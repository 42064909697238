import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/ProjectDetails.css"; // Assuming you have a custom CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faCalendarDays, faTablet, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'; // Import the arrow icons
import Spinner from "react-bootstrap/Spinner";
import { ModeContext } from '../../contexts/mode/ModeContext';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const { isSandboxMode } = useContext(ModeContext);

  useEffect(() => {
    // Fetch the project details
    const fetchProjectDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.PROJECT}/${projectId}?isSandbox=${isSandboxMode}`);
        setProject(response.data);
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };
    fetchProjectDetails();
  }, [projectId, isSandboxMode]);

  if (!project) {
    return <Container className="dashboard"><Spinner /></Container>;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Link to={`/profile/portfolio/${project.portfolio._id}`} className="back-link">
            <span style={{ color: 'black' }}><FontAwesomeIcon icon={faArrowLeftLong} /> Back to Portfolio</span>
          </Link>
        </Col>
      </Row>
      <Row className="mt-4 align-items-center">
        <Col md="8">
          <h1 className="project-title">{project.name}</h1>
          <div className="project-info">
            <div className="project-type">
              <FontAwesomeIcon icon={faTablet} className="project-icon" />
              <span className="label">Type: </span> {project.type}
            </div>
            <div className="project-developer">
              <FontAwesomeIcon icon={faIdCard} className="project-icon" />
              <span className="label">Developer: </span> {project.developer}
            </div>
            <div className="project-vintage">
              <FontAwesomeIcon icon={faCalendarDays} className="project-icon" />
              <span className="label">Vintage: </span> {project.vintages?.join(', ')}
            </div>
          </div>
        </Col>
        <Col md="4">
          <img src={project.image || '/default-image.jpg'} alt={project.name} className="project-image" />
        </Col>
      </Row>


      <Row className="mt-4">
        {/* Project Description */}
        <Col md="12">
          <Card className="description-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
            <CardBody>
              <h5>Project Description</h5>
              <p>{project.description || 'No description provided.'}</p>
            </CardBody>
          </Card>
        </Col>

        {/* Third Party Ratings */}
        <Col md="6" className="mt-4">
          <Card className="ratings-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
            <CardBody>
              <h5>Third-Party Ratings</h5>
              {project.thirdPartyRatings.length > 0 ? (
                <ul>
                  {project.thirdPartyRatings.map((rating, index) => (
                    <li key={index}>
                      {rating.company}: {rating.rating}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No third-party ratings.</p>
              )}
            </CardBody>
          </Card>
        </Col>

        {/* Carbon Registry */}
        <Col md="6" className="mt-4">
          <Card className="ratings-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
            <CardBody>
              <h5>Carbon Registry</h5>
              {project.carbonRegistry ? (
                <ul>
                  <li>
                    {project.carbonRegistry.name}: {project.carbonRegistry.id}
                  </li>
                </ul>
              ) : (
                <p>No carbon registry.</p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>


      <Row className="mt-4">
        {/* Sustainable Development Goals */}
        <Col md="6">
          <Card className="sustainable-goals-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
            <CardBody>
              <h5>KSA Sustainable Development Goals</h5>
              {project.sustainableDevelopment.length > 0 ? (
                <div className="goals">
                  {project.sustainableDevelopment.map((goal, index) => (
                    <>
                      <img
                        key={index}
                        src={goal}
                        alt={`Sustainable Development Goal ${index + 1}`}
                        className="goal-image"
                      />
                      <span className="goal-tooltip">TEST</span>
                    </>
                  ))}
                </div>
              ) : (
                <p>No sustainable development goals listed.</p>
              )}
            </CardBody>
          </Card>
        </Col>

        {/* Location */}
        <Col md="6">
          <Card className="location-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
            <CardBody>
              <h5>Location</h5>
              <p>{project.location || 'Location not provided'}</p>
              {/* Include any map or location detail here */}
              <div className="map-placeholder">
                <p>Map here if available</p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectDetails;
