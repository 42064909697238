import React, { useState, useEffect } from 'react';
import { Table, Button, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await http.get(API_ENDPOINTS.USERS);
            setUsers(response.data.users);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    const handleUserClick = (userId) => {
        navigate(`/profile/admin/users/${userId}`);
    };

    return (
        <Container className="dashboard">
            <h2>Users</h2>
            {loading ? <Spinner /> : (
                <Table striped>
                    <thead>
                        <tr>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>
                                <td><Button color="link" onClick={e => handleUserClick(user._id)}>{user.email}</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};

export default UsersPage;
