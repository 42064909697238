import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label, Container } from 'reactstrap';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import { useNavigate } from 'react-router-dom';
import { ModeContext } from '../../../contexts/mode/ModeContext';

const sustainableDevelopmentOptions = [
    { label: 'No Poverty', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1729079986/bzlznadeoefffvrztuuc.jpg' },
    { label: 'Zero Hunger', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1729080037/g6xzifhdypghti06s57l.jpg' },
    { label: 'Good Health and Well-being', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1729080079/c7a4vwbbdiselvdjtzcl.jpg' },
    { label: 'Quality Education', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1729080115/ohb48ggufa6la0pon0zk.jpg' },
    { label: 'Gender Equality', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374645/pkasulrnbpt1jhzyt306.jpg' },
    { label: 'Clean Water & Sanitation', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374703/hlwlhjlzuecmyz79du8l.jpg' },
    { label: 'Affordable & Clean Energy', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374754/rvcgiyrzxsuwlqfrp32e.jpg' },
    { label: 'Decent Work & Economic Growth', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374800/ema2iqgkrpqqw6lecywe.jpg' },
    { label: 'Industry, Innovation, & Infrastructure', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374856/sl94mapsndibayxowz1h.jpg' },
    { label: 'Reduced Inequality', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374918/tbvtdhksg7qrdjtcmels.jpg' },
    { label: 'Sustainable Cities & Communities', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730374971/vjy9omhxt6v8lfkiiox2.jpg' },
    { label: 'Reasonable Consumption & Production', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730375022/okrggpmfdndslivahuue.jpg' },
    { label: 'Climate Action', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730375077/ngbsjyiubftw8ckr4m67.jpg' },
    { label: 'Life Below Water', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730375125/ksjikmrsyns8hrzrdkej.jpg' },
    { label: 'Life On Land', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730375163/qpiaberlsvyoea9flihh.jpg' },
    { label: 'Peace, Justice, & Strong Institutions', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730375204/zbx330cepgg8tg47ojfu.jpg' },
    { label: 'Partnerships For The Goals', imageUrl: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1730375258/w3es3t84cxazzj875djv.jpg' },
];

const registryOptions = [
    { label: 'Verra', id: 1 },
    { label: 'Gold Standard', id: 2 },
    { label: 'ICR', id: 3 },
    {label: 'UN', id: 4}
];

const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [portfolios, setPortfolios] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState('');
    const { isSandboxMode } = useContext(ModeContext);
    const [newProject, setNewProject] = useState({
        name: '',
        description: '',
        location: '',
        developer: '',
        type: '',
        portfolio: '',
        category: '',
        thirdPartyRatings: [{ company: '', rating: '' }],
        carbonRegistry: { name: '', id: '' },
        sustainableDevelopment: [],
    });
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchProjects();
        fetchPortfolios();
        fetchCategories();
    }, [isSandboxMode]);

    const fetchProjects = async () => {
        try {
            const response = await http.get(`${API_ENDPOINTS.PROJECTS}?isSandbox=${isSandboxMode}`);
            setProjects(response.data.projects);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const fetchPortfolios = async () => {
        try {
            const response = await http.get(API_ENDPOINTS.PORTFOLIO);
            setPortfolios(response.data.portfolios);
        } catch (error) {
            console.error('Error fetching portfolios:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await http.get(API_ENDPOINTS.PROJECT_CATEGORIES);
            setCategories(response.data.categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const handleProjectInputChange = (e) => {
        const { name, value } = e.target;
        setNewProject({ ...newProject, [name]: value });
    };

    const handleThirdPartyRatingsChange = (index, field, value) => {
        const updatedRatings = [...newProject.thirdPartyRatings];
        updatedRatings[index][field] = value;
        setNewProject({ ...newProject, thirdPartyRatings: updatedRatings });
    };

    const handleAddRating = () => {
        setNewProject({ ...newProject, thirdPartyRatings: [...newProject.thirdPartyRatings, { company: '', rating: '' }] });
    };

    const handleSustainableDevelopmentChange = (imageUrl) => {
        setNewProject((prevProject) => {
            const alreadySelected = prevProject.sustainableDevelopment.includes(imageUrl);
            const updatedSelections = alreadySelected
                ? prevProject.sustainableDevelopment.filter(url => url !== imageUrl)
                : [...prevProject.sustainableDevelopment, imageUrl];
            return { ...prevProject, sustainableDevelopment: updatedSelections };
        });
    };

    const handleRegistryChange = (selectedOption) => {
        const registry = registryOptions.find(option => option.label === selectedOption);
        setNewProject({ ...newProject, carbonRegistry: { name: registry.label, id: registry.id } });
    };

    const handleAddProject = async () => {
        try {
            const response = await http.post(API_ENDPOINTS.PROJECTS, newProject);
            setProjects((prevProjects) => [...prevProjects, response.data.project]);
            setNewProject({
                name: '',
                description: '',
                developer: '',
                type: '',
                portfolio: '',
                location: '',
                category: '',
                thirdPartyRatings: [{ company: '', rating: '' }],
                carbonRegistry: { name: '', id: '' },
                sustainableDevelopment: [],
            });
            toggleModal();
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    return (
        <Container className="dashboard">
            <div className="d-flex justify-content-between">
                <h2>Projects</h2>
                <Button color="primary" onClick={toggleModal} className="rotate-button" style={{ borderRadius: '30px', padding: '6px 14px' }}>Add Project</Button>
            </div>

            {/* Filter by Portfolio */}
            <div className="mb-4">
                <label htmlFor="portfolioFilter">Filter by Portfolio:</label>
                <Input type="select" id="portfolioFilter" value={selectedPortfolio} onChange={(e) => setSelectedPortfolio(e.target.value)}>
                    <option value="">All Portfolios</option>
                    {portfolios.map(portfolio => (
                        <option key={portfolio._id} value={portfolio._id}>{portfolio.name}</option>
                    ))}
                </Input>
            </div>

            {/* Project List Table */}
            <Table striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Developer</th>
                        <th>Portfolio</th>
                        <th>Carbon Registry</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map(project => (
                        <tr key={project._id} onClick={() => navigate(`/profile/admin/projects/${project._id}`)} style={{ cursor: 'pointer' }}>
                            <td><Button color="link">{project.name}</Button></td>
                            <td>{project.projectCategory.name}</td>
                            <td>{project.developer}</td>
                            <td>{project.portfolio.name}</td>
                            <td>{project.carbonRegistry.name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add Project Modal */}
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Add New Project</ModalHeader>
                <ModalBody>
                    <h6>Project Details</h6>
                    <Row form>
                        <Col md={6}>
                            <Label for="name">Project Name</Label>
                            <Input
                                type="text"
                                name="name"
                                value={newProject.name}
                                onChange={handleProjectInputChange}
                                placeholder="Project Name"
                            />
                        </Col>
                        <Col md={6}>
                            <Label for="description">Project Description</Label>
                            <Input
                                type="text"
                                name="description"
                                value={newProject.description}
                                onChange={handleProjectInputChange}
                                placeholder="Project Description"
                            />
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <Label for="developer">Developer</Label>
                            <Input
                                type="text"
                                name="developer"
                                value={newProject.developer}
                                onChange={handleProjectInputChange}
                                placeholder="Developer"
                            />
                        </Col>
                        <Col md={4}>
                            <Label for="location">Location</Label>
                            <Input
                                type="text"
                                name="location"
                                value={newProject.location}
                                onChange={handleProjectInputChange}
                                placeholder="Location"
                            />
                        </Col>
                        <Col md={4}>
                            <Label for="type">Type</Label>
                            <Input
                                type="text"
                                name="type"
                                value={newProject.type}
                                onChange={handleProjectInputChange}
                                placeholder="Type"
                            />
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <Label for="portfolio">Portfolio</Label>
                            <Input type="select" name="portfolio" value={newProject.portfolio} onChange={handleProjectInputChange}>
                                <option value="">Select Portfolio</option>
                                {portfolios.map(portfolio => (
                                    <option key={portfolio._id} value={portfolio._id}>{portfolio.name}</option>
                                ))}
                            </Input>
                        </Col>
                        <Col md={6}>
                            <Label for="category">Category</Label>
                            <Input type="select" name="category" value={newProject.category} onChange={handleProjectInputChange}>
                                <option value="">Select Category</option>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>{category.name}</option>
                                ))}
                            </Input>
                        </Col>
                    </Row>

                    <h6>Carbon Registry</h6>
                    <Row form>
                        <Col md={6}>
                            <Label for="carbonRegistry">Select Registry</Label>
                            <Input type="select" value={newProject.carbonRegistry.name} onChange={(e) => handleRegistryChange(e.target.value)}>
                                <option value="">Select Carbon Registry</option>
                                {registryOptions.map(option => (
                                    <option key={option.id} value={option.label}>{option.label}</option>
                                ))}
                            </Input>
                        </Col>
                    </Row>

                    <h6>Third-Party Ratings</h6>
                    {newProject.thirdPartyRatings.map((rating, index) => (
                        <Row key={index} form className="mb-2">
                            <Col md={6}>
                                <Input
                                    type="text"
                                    placeholder="Company"
                                    value={rating.company}
                                    onChange={(e) => handleThirdPartyRatingsChange(index, 'company', e.target.value)}
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    type="text"
                                    placeholder="Rating"
                                    value={rating.rating}
                                    onChange={(e) => handleThirdPartyRatingsChange(index, 'rating', e.target.value)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Button color="secondary" onClick={handleAddRating} block>Add Another Rating</Button>

                    <h6>Sustainable Development Goals</h6>
                    {sustainableDevelopmentOptions.map(option => (
                        <div key={option.label} className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={option.label}
                                checked={newProject.sustainableDevelopment.includes(option.imageUrl)}
                                onChange={() => handleSustainableDevelopmentChange(option.imageUrl)}
                            />
                            <label className="form-check-label" htmlFor={option.label}>
                                <img src={option.imageUrl} alt={option.label} style={{ width: '50px', marginRight: '10px' }} />
                                {option.label}
                            </label>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddProject}>Add Project</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default ProjectsPage;
