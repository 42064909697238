import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import NavbarQuiz from "../../../Layout/NavbarQuiz";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import UserContext from '../../../contexts/user/UserContext';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { GoogleLogin } from '@react-oauth/google';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useContext(UserContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false); // Loading state

  const from = location.state?.from || "/";

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when request starts
    try {
      const res = await http.post(API_ENDPOINTS.LOGIN, {
        email,
        password,
      });

      // Handle successful registration
      if (res.status === 200) {
        const { user, token } = res.data;
        signIn(user);
        Cookies.set('auth_token', token, { expires: 7 });
        // Redirect to the previous page or home if no previous page is found
        //if (user.completedQuiz) {
        navigate('/profile/home');
        {/*}
        else {
          navigate('/quiz/welcome');
        }*/}
      }
    } catch (err) {
      // Handle error during registration
      setError(err.response?.data?.message || "Login failed.");
    }
    finally {
      setLoading(false); // Set loading to false when request is complete
    }
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <React.Fragment>
      <NavbarQuiz />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center justify-content-center">
              <Col lg={6}>
                <h2 className="text-center mb-4">Login</h2>
                <Form>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    {error && <div className="error-message">{error}</div>}
                  </FormGroup>
                  <Button type="submit" onClick={handleLogin} color="primary" block disabled={loading}>
                    {loading ? <Spinner size="sm" /> : 'Login'}
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
                </div>
                <div className="text-center mt-4">
                  <p>
                    By using c-mass, you agree to the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

      </section>
    </React.Fragment>
  );
};

export default LoginPage;
