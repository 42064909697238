import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../../../contexts/mode/ModeContext';

import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import Spinner from "react-bootstrap/Spinner";

const ProjectDetailsPage = () => {
    const { projectId } = useParams(); // Get the projectId from the URL params
    const [project, setProject] = useState(null); // Store the project data
    const [creditBatches, setCreditBatches] = useState([]); // Store the credit batches data
    const [loading, setLoading] = useState(true); // Handle loading state
    const [error, setError] = useState(null); // Handle error state
    const [modalOpen, setModalOpen] = useState(false); // Control modal visibility
    const { isSandboxMode } = useContext(ModeContext);

    const [newCreditBatch, setNewCreditBatch] = useState({
        totalCredits: '',
        availableCredits: '',
        issuedOn: '',
        expiresOn: '',
        vintage: '',
        governingBody: '',
        certificateId: ''
    });

    // Fetch the project details and associated credit batches when the component mounts
    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const projectResponse = await http.get(`${API_ENDPOINTS.PROJECTS}/${projectId}?isSandbox=${isSandboxMode}`);
                const creditBatchResponse = await http.get(`${API_ENDPOINTS.CREDIT_BATCHES}?projectId=${projectId}&isSandbox=${isSandboxMode}`);
                setProject(projectResponse.data.project);
                setCreditBatches(creditBatchResponse.data.batches);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project details:', error);
                setError('Failed to fetch project details');
                setLoading(false);
            }
        };
        fetchProjectDetails();
    }, [projectId]);

    // Handle loading and error states
    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle input changes for new credit batch
    const handleCreditBatchInputChange = (e) => {
        const { name, value } = e.target;
        setNewCreditBatch({ ...newCreditBatch, [name]: value });
    };

    // Generate batch number dynamically
    const generateBatchNumber = () => {
        const dateStr = new Date().toISOString().split('.')[0]; // YYYY-MM-DD
        return `${project.carbonRegistry.name}-${project.name}-${dateStr}`;
    };

    // Handle adding new credit batch
    const handleAddCreditBatch = async () => {
        try {
            const batchData = {
                project: projectId,
                batchNumber: generateBatchNumber(),
                totalCredits: newCreditBatch.totalCredits,
                availableCredits: newCreditBatch.availableCredits,
                issuedOn: newCreditBatch.issuedOn,
                expiresOn: newCreditBatch.expiresOn,
                vintage: newCreditBatch.vintage,
                governingBody: newCreditBatch.governingBody,
                certificateId: newCreditBatch.certificateId
            };
            await http.post(`${API_ENDPOINTS.CREDIT_BATCHES}?isSandbox=${isSandboxMode}`, batchData);
            toggleModal(); // Close the modal
            // Fetch updated credit batches
            const creditBatchResponse = await http.get(`${API_ENDPOINTS.CREDIT_BATCHES}?project=${projectId}&isSandbox=${isSandboxMode}`);
            setCreditBatches(creditBatchResponse.data.batches);
        } catch (error) {
            console.error('Error adding credit batch:', error);
        }
    };

    return (
        <Container className="dashboard">
            <h2>Project Details</h2>
            {project ? (
                <div>
                    <p><strong>Name:</strong> {project.name}</p>
                    <p><strong>Location:</strong> {project.location}</p>
                    <p><strong>Description:</strong> {project.description}</p>
                    <p><strong>Developer:</strong> {project.developer}</p>
                    <p><strong>Registry:</strong> {project.carbonRegistry.name} (ID: {project.carbonRegistry.id})</p>

                    <h3>Credit Batches</h3>
                    <Button color="primary" onClick={toggleModal} style={{ borderRadius: '30px', padding: '6px 14px' }}>
                        Add Credit Batch
                    </Button>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Batch Number</th>
                                <th>Total Credits (kg)</th>
                                <th>Available Credits (kg)</th>
                                <th>Issued On</th>
                                <th>Expires On</th>
                                <th>Vintage</th>
                                <th>Governing Body</th>
                                <th>Certificate ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {creditBatches.map((batch, index) => (
                                <tr key={index}>
                                    <td>{batch.batchNumber}</td>
                                    <td>{batch.totalCredits}</td>
                                    <td>{batch.availableCredits}</td>
                                    <td>{new Date(batch.issuedOn).toLocaleDateString()}</td>
                                    <td>{batch.expiresOn ? new Date(batch.expiresOn).toLocaleDateString() : 'N/A'}</td>
                                    <td>{new Date(batch.vintage).toLocaleDateString()}</td>
                                    <td>{batch.governingBody}</td>
                                    <td>{batch.certificateId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Add Credit Batch Modal */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Add New Credit Batch</ModalHeader>
                        <ModalBody>
                            <p><strong>Batch Number:</strong> {generateBatchNumber()}</p>
                            <div className="mb-3">
                                <label>Total Credits (kg)</label>
                                <input
                                    type="number"
                                    name="totalCredits"
                                    value={newCreditBatch.totalCredits}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Available Credits (kg)</label>
                                <input
                                    type="number"
                                    name="availableCredits"
                                    value={newCreditBatch.availableCredits}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Issued On</label>
                                <input
                                    type="date"
                                    name="issuedOn"
                                    value={newCreditBatch.issuedOn}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Expires On</label>
                                <input
                                    type="date"
                                    name="expiresOn"
                                    value={newCreditBatch.expiresOn}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Vintage</label>
                                <input
                                    type="date"
                                    name="vintage"
                                    value={newCreditBatch.vintage}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Governing Body</label>
                                <input
                                    type="text"
                                    name="governingBody"
                                    value={newCreditBatch.governingBody}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Certificate ID</label>
                                <input
                                    type="text"
                                    name="certificateId"
                                    value={newCreditBatch.certificateId}
                                    onChange={handleCreditBatchInputChange}
                                    className="form-control mb-2"
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={handleAddCreditBatch}>Add Credit Batch</Button>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            ) : (
                <p>No project details available</p>
            )}
        </Container>
    );
};

export default ProjectDetailsPage;
