// SetupPayment.js
import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import SavePaymentComponent from '../../components/SavePaymentComponent';
import { ModeContext } from '../../contexts/mode/ModeContext';
import { useNavigate, useOutletContext } from 'react-router-dom';

const SetupPayment = () => {
    const { isSandboxMode } = useContext(ModeContext);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { company } = useOutletContext();

    return (
        <Container fluid className="dashboard">
            <Row className="justify-content-center align-items-center" style={{ paddingTop: '50px' }}>
                <Col md="6" lg="5">
                    <h2 className="mb-4">Set up payment method</h2>
                    <h1 style={{ fontSize: '48px', fontWeight: 'bold' }}>SAR 10.00 <small style={{ fontSize: '24px', color: 'gray' }}>due today</small></h1>
                    <p>Then billed monthly for purchases of CO₂e credits made during the prior month.</p>
                    <p>Unless otherwise agreed upon, pricing will always be available on our <a href="/pricing">pricing page</a>, which may be updated periodically.</p>
                </Col>
                <Col md="6" lg="5">
                    <Card style={{ borderRadius: '15px' }}>
                        <CardBody>
                            <SavePaymentComponent companyId={company._id}/>
                            {error && <Alert color="danger" className="mt-3">{error}</Alert>}
                            {success && <Alert color="success" className="mt-3">{success}</Alert>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SetupPayment;
