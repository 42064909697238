export const API_ENDPOINTS = {
    SECTORS: '/sectors',
    SAVE_COMPANY_DATA: '/company/save-company-data',
    LOGIN: '/user/login',
    SIGNUP: '/user/signup',
    PROMO_SIGNUP: '/user/promo-signup',
    SCOPES_EMISSIONS: '/emissions/scopes',
    TOP_CONTRIBUTORS: '/emissions/top-emissions',
    FULL_REPORT: '/emissions/full-report',
    COMPANY: '/company',
    PURCHASE_ORDER: '/company/order',
    COMPANY_ORDERS_SUMMERY: '/company/ordersSummery',
    COMPANY_ORDERS: '/company/orders',
    PORTFOLIO: '/portfolio',
    GET_PORTFOLIO: '/portfolio/getPortfolio',
    INQUIRY: '/mail/inquiry',
    ORDER_DETAILS: '/order',
    PUBLIC_ORDER_DETAILS: '/public/order',
    CREATE_APIKEY: '/apikeys/create',
    REVOKE_SANDBOX_APIKEY: '/apikeys/revoke',
    GET_LIVE_API_KEYS: '/apikeys/live',
    GET_SANDBOX_API_KEYS: '/apikeys/sandbox',
    PUBLIC_SHARE_IMPACT: '/public/share-impact',
    PUBLIC_SHARE_ORDER_IMPACT: '/public/share-order-impact',
    PROJECT: '/project',
    PROJECTS_BY_IDS: '/project/byIds',
    PROJECT_RECORDS_BY_IDS: '/project/projectRecordsById',
    ORDERS_SUMMERY: '/order/summery',
    ORDERS_FULFILLED: '/order/fulfilled',
    ORDERS: '/order/orders-list',
    SUBMIT_ORDER: '/order',
    SUPPORTED_PROJECTS: '/project/supported',
    LEADS: '/admin/leads',
    LEAD: '/admin/leads',
    PROJECTS: '/admin/projects',
    PROJECT_CATEGORIES: '/admin/projects/categories',
    CREDIT_BATCHES: '/admin/projects/credit-batches',
    USERS: '/admin/users',
    AUDIT_LOGS: '/admin/audit',
    SAVE_PAYMENT_INFO: '/payment-info/save'
}