import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Card, CardBody, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree, faCar, faHome, faPlane, faCalendarAlt, faDownload, faFilter, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import UserContext from "../../contexts/user/UserContext";
import { ModeContext } from '../../contexts/mode/ModeContext';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { useNavigate, useOutletContext } from 'react-router-dom';
import OxfordCategoriesChart from '../../components/OxfordCategoriesChart';
import ProjectTypesChart from '../../components/ProjectTypesChart';
import CountriesChart from '../../components/CountriesChart';
import Spinner from "react-bootstrap/Spinner";
import logo from '../../assets/images/nz-logo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import '../../assets/css/MyImpact.css'; // Custom CSS file for styling

const MyImpact = () => {
    const { user } = useContext(UserContext);
    const { isSandboxMode } = useContext(ModeContext);
    const [orders, setOrders] = useState([]);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const { company } = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end ? format(end, 'yyyy-MM-dd') : null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS_FULFILLED}/${company._id}?isSandbox=${isSandboxMode}`);
                setOrders(ordersResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setOrders([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [user, company._id, isSandboxMode]);

    const generateImpactReportPDF = () => {
        const pdf = new jsPDF("p", "pt", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        let y = 40; // Starting y-position for the header

        // Logo (Top-Left Corner)
        const logoWidth = 50;
        const logoHeight = 50;
        const logoX = 40;
        const logoY = 20;
        pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Adjust y-position based on the logo height
        y = logoY + logoHeight + 20;

        // Header Section
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(28);
        pdf.setTextColor('#222222');
        pdf.text("Impact Report", pageWidth / 2, y, { align: "center" });
        y += 35;

        pdf.setFontSize(18);
        pdf.setTextColor('#555555');
        pdf.text(company.name, pageWidth / 2, y, { align: "center" });
        y += 25;

        pdf.setFontSize(14);
        pdf.setTextColor('#000000');
        pdf.text("September 6 - November 5, 2024", pageWidth / 2, y, { align: "center" });
        y += 50;

        // Main Card: Total Carbon Credits Purchased
        const cardWidth = 180;
        const cardHeight = 130;
        const cardX = 40;
        const cardY = y;

        pdf.setFillColor('#F9F3EA'); // Light beige background color
        pdf.roundedRect(cardX, cardY, cardWidth, cardHeight, 10, 10, 'F');

        const textX = cardX + 15;
        let textY = cardY + 20;

        pdf.setFontSize(8);
        pdf.setTextColor('#888888');
        pdf.text("Total amount of carbon credits \npurchased", textX, textY);
        textY += 25;
        textY += 20;
        pdf.setFontSize(32);
        pdf.setTextColor('#000000'); // Black color for the number
        pdf.text(`${orders?.totalCreditsPurchased || 0}`, textX, textY);
        textY += 25;

        pdf.setFontSize(14);
        pdf.setTextColor('#888888');
        pdf.text("kg", textX + 5, textY);

        // Move to the next section
        y += cardHeight + 30;

        // Secondary Metrics Section
        const metrics = [
            { title: "New trees planted", value: `${(orders?.totalCreditsPurchased * 0.022).toFixed(2)}` },
            { title: "Cars off the road for a year", value: `${((orders?.totalCreditsPurchased) * 0.00021).toFixed(2)}` },
            { title: "Homes' annual energy use", value: `${((orders?.totalCreditsPurchased) * 0.00022).toFixed(2)}` },
            { title: "Flights from LA to New York", value: `${((orders?.totalCreditsPurchased) * 0.0011).toFixed(2)}` },
        ];

        // Position and spacing for each metric card
        const metricCardWidth = 160;
        const metricCardHeight = 50;
        const metricStartX = cardX + cardWidth + 20; // Start right after the main card
        let metricX = metricStartX;
        let metricY = cardY;

        metrics.forEach((metric, index) => {
            // Draw each metric box with background color
            pdf.setFillColor('#F9F3EA'); // Light beige background color
            pdf.roundedRect(metricX, metricY, metricCardWidth, metricCardHeight, 5, 5, 'F');

            // Metric Title
            pdf.setFontSize(8);
            pdf.setTextColor('#888888');
            pdf.text(metric.title, metricX + 10, metricY + 15);

            // Metric Value
            pdf.setFontSize(18);
            pdf.setTextColor('#333333');
            pdf.text(metric.value, metricX + 10, metricY + 35);

            // Position the next box
            if (index % 2 === 0) {
                metricX += metricCardWidth + 10; // Move to the right for the next column
            } else {
                metricX = metricStartX; // Reset x position
                metricY += metricCardHeight + 10; // Move down for the next row
            }
        });

        // Disclaimer text
        pdf.setFontSize(10);
        pdf.setTextColor('#999999');
        pdf.text("* calculations based on GCOM and ICAO formulas", metricStartX + 5, metricY + 8);

        // Perform Calculations for Each Section
        const calculateCategoryData = (orders) => {
            const categoryMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const categoryName = record.projectCategoryId?.name || "Unknown Category";
                    const credits = Math.abs(record.delta);
                    categoryMap[categoryName] = (categoryMap[categoryName] || 0) + credits;
                });
            });
            const totalCredits = Object.values(categoryMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(categoryMap).map(categoryName => ({
                label: categoryName,
                percentage: ((categoryMap[categoryName] / totalCredits) * 100).toFixed(1)
            }));
        };

        const calculateCountryData = (orders) => {
            const countryMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const country = record.projectId?.location || 'Unknown Country';
                    const credits = Math.abs(record.delta);
                    countryMap[country] = (countryMap[country] || 0) + credits;
                });
            });
            const totalCredits = Object.values(countryMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(countryMap).map(country => ({
                label: country,
                percentage: ((countryMap[country] / totalCredits) * 100).toFixed(1)
            }));
        };

        const calculateProjectTypeData = (orders) => {
            const projectTypeMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const projectType = record.projectId?.type || 'Unknown Type';
                    const credits = Math.abs(record.delta);
                    projectTypeMap[projectType] = (projectTypeMap[projectType] || 0) + credits;
                });
            });
            const totalCredits = Object.values(projectTypeMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(projectTypeMap).map(projectType => ({
                label: projectType,
                percentage: ((projectTypeMap[projectType] / totalCredits) * 100).toFixed(1)
            }));
        };

        const calculateProjectData = (orders) => {
            const projectMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const projectName = record.projectId?.name || 'Unknown Project';
                    const credits = Math.abs(record.delta);
                    projectMap[projectName] = (projectMap[projectName] || 0) + credits;
                });
            });
            const totalCredits = Object.values(projectMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(projectMap).map(project => ({
                label: project,
                percentage: ((projectMap[project] / totalCredits) * 100).toFixed(1)
            }));
        };

        // Calculated Data for Each Section
        const oxfordCategories = calculateCategoryData(orders.orders);
        const countries = calculateCountryData(orders.orders);
        const projectTypes = calculateProjectTypeData(orders.orders);
        const projects = calculateProjectData(orders.orders);

        // Colors for Dots and Bars
        const colors = ['#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B'];

        // Render Section Function
        const renderSection = (title, data, startX, startY) => {
            pdf.setFontSize(16);
            pdf.setTextColor('#222222');
            pdf.setFont('Helvetica', 'bold');
            pdf.text(title, startX, startY);
            startY += 20;

            data.forEach((item, index) => {
                pdf.setFillColor(colors[index % colors.length]);
                pdf.circle(startX + 5, startY + 7, 4, 'F'); // Color dot

                pdf.setFontSize(8);
                pdf.setTextColor('#333333');
                pdf.text(item.label, startX + 15, startY + 10);

                pdf.setTextColor('#333333');
                pdf.text(`${item.percentage}%`, startX + 150, startY + 10);

                // Progress Bar
                //pdf.setFillColor(colors[index % colors.length]);
                //pdf.roundedRect(startX + 2, startY + 15, (item.percentage * 1.5), 8, 2, 2, 'F');

                startY += 30;
            });
            return startY + 10; // Return the new y-position after rendering
        };

        // Render Oxford Categories, Countries, Project Types, Projects in Two Columns
        const leftColumnX = 40;
        const rightColumnX = pageWidth / 2 + 20;
        let currentY = y;

        currentY = renderSection("Oxford Categories", oxfordCategories, leftColumnX, currentY);
        currentY = renderSection("Project Types", projectTypes, leftColumnX, currentY);

        let rightColumnY = y;
        rightColumnY = renderSection("Countries", countries, rightColumnX, rightColumnY);
        rightColumnY = renderSection("Projects", projects, rightColumnX, rightColumnY);

        // Function to calculate unique sustainability goals images
        const getUniqueSustainableDevelopmentGoals = (orders) => {
            const uniqueImages = new Set();
            orders.forEach(order => {
                order.projectRecords.forEach(record => {
                    record.projectId.sustainableDevelopment?.forEach(url => {
                        uniqueImages.add(url)
                    });
                });
            });
            return Array.from(uniqueImages); // Convert the Set back to an Array for rendering
        };

        // Render Sustainable Development Goals Section
        const renderSustainableDevelopmentGoals = (pdf, y, uniqueSDGImages) => {
            pdf.setFontSize(16);
            pdf.setTextColor('#222222');
            pdf.setFont('Helvetica', 'bold');
            pdf.text("Sustainable Development Goals", pageWidth / 2, y, { align: "center" });
            y += 20;

            const imageWidth = 35;
            const imageHeight = 35;
            const padding = 10; // Padding between images
            let x = 40; // Starting x-position for images
            uniqueSDGImages.forEach((url) => {
                if (x + imageWidth > pageWidth - 40) { // Move to the next line if out of space
                    x = 40;
                    y += imageHeight + padding;
                }
                pdf.addImage(url, 'JPEG', x, y, imageWidth, imageHeight);
                x += imageWidth + padding;
            });

            return y + imageHeight + 20; // Return the new y-position after rendering
        };

        // Get unique sustainability goal images
        const uniqueSDGImages = getUniqueSustainableDevelopmentGoals(orders.orders);

        // Render the Sustainable Development Goals section at the bottom
        let finalYPosition = Math.max(currentY, rightColumnY);
        finalYPosition = renderSustainableDevelopmentGoals(pdf, finalYPosition, uniqueSDGImages);

        
        // Save the PDF
        pdf.save("Impact_Report.pdf");
    };


    return (
        <Container className="dashboard">
            {loading ? <Spinner /> :
                <>
                    <Row className="align-items-center mb-4">
                        <Col md="6">
                            <h2 className="impact-title">Impact Metrics</h2>
                        </Col>
                        <Col md="6" className="text-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {/*<div style={{ marginRight: '10px' }}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>

                            <div style={{ marginRight: '10px' }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText="Select date range"
                                    dateFormat="YYYY-MM-DD"
                                    isClearable={true}
                                    customInput={<Input />}
                                />
                            </div>*/}

                            <Button color="secondary" outline style={{ borderRadius: '25px' }} onClick={generateImpactReportPDF}>
                                <FontAwesomeIcon icon={faDownload} /> Download PDF
                            </Button>
                        </Col>

                    </Row>


                    <Row className="mb-4">
                        <Col md="4">
                            <Card className="total-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                                <CardBody>
                                    <p className="total-description"><FontAwesomeIcon icon={faLeaf} style={{ color: "#00b295" }} /> Total amount of carbon credits purchased</p>
                                    <h1 className="total-value">{orders?.totalCreditsPurchased ? orders?.totalCreditsPurchased : 0} kg</h1>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row className="impact-section mb-4">
                        <Col md="3">
                            <Card className="impact-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                                <CardBody>
                                    <p className="total-description"><FontAwesomeIcon icon={faTree} /> New trees planted</p>
                                    <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.022).toFixed(2) : 0}</h3>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card className="impact-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                                <CardBody>
                                    <p className="total-description"><FontAwesomeIcon icon={faCar} /> Cars off the road</p>
                                    <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.00021).toFixed(2) : 0}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card className="impact-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                                <CardBody>
                                    <p className="total-description"><FontAwesomeIcon icon={faHome} /> Homes' energy use</p>
                                    <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.00022).toFixed(2) : 0}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card className="impact-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                                <CardBody>
                                    <p className="total-description"><FontAwesomeIcon icon={faPlane} /> Flights from RUH-LHR</p>
                                    <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.0011).toFixed(2) : 0}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row className="statistics-section">
                        <Col md="3">
                            <OxfordCategoriesChart orders={orders.orders} />
                        </Col>
                        <Col md="3">
                            <ProjectTypesChart orders={orders.orders} />
                        </Col>
                        <Col md="3">
                            <CountriesChart orders={orders.orders} />
                        </Col>
                    </Row></>}
        </Container>
    );
};

export default MyImpact;
