import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import UserContext from '../../../contexts/user/UserContext'; // To get the logged-in user
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import { useNavigate } from 'react-router-dom';

const LeadsPage = () => {
    const { user } = useContext(UserContext); // Get logged-in user data
    const [leads, setLeads] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const [newLead, setNewLead] = useState({
        name: '',
        contactInfo: [], // Multiple contacts
        projects: [],
        location: '',
    });

    const [newContact, setNewContact] = useState({
        name: '',
        title: '',
        email: '',
        phone: '',
    });

    const [newProject, setNewProject] = useState({
        name: '',
        location: '',
        type: '',
        credits: '',
        price: '',
        vintage: '', 
        registry: '', 
        availabilityNow: true,
        availabilityDate: ''
    });

    // Fetch leads on component mount
    useEffect(() => {
        fetchLeads();
    }, []);

    const fetchLeads = async () => {
        try {
            const response = await http.get(API_ENDPOINTS.LEADS);
            setLeads(response.data.leads);
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewLead({ ...newLead, [name]: value });
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setNewContact({ ...newContact, [name]: value });
    };

    const addContact = () => {
        setNewLead({
            ...newLead,
            contactInfo: [...newLead.contactInfo, newContact],
        });
        setNewContact({
            name: '',
            title: '',
            email: '',
            phone: '',
        });
    };

    const handleProjectChange = (e) => {
        const { name, value } = e.target;
        setNewProject({ ...newProject, [name]: value });
    };

    const addProject = () => {
        setNewLead({
            ...newLead,
            projects: [...newLead.projects, newProject],
        });
        setNewProject({
            name: '',
            location: '',
            type: '',
            credits: '',
            price: '',
            vintage: '',  
            registry: '',  
            availabilityNow: true,
            availabilityDate: ''
        });
    };

    const handleAddLead = async () => {
        try {
            const leadData = {
                ...newLead,
                addedOn: new Date().toISOString().split('T')[0],
            };

            await http.post(API_ENDPOINTS.LEADS, leadData);

            fetchLeads();

            setNewLead({
                name: '',
                contactInfo: [],
                projects: [],
                location: '',
            });
            toggleModal();
        } catch (error) {
            console.error('Error adding lead:', error);
        }
    };

    const handleLeadClick = (leadId) => {
        navigate(`/profile/admin/leads/${leadId}`);
    };

    return (
        <Container className="dashboard">
            <div className="d-flex justify-content-between">
                <h2>Leads</h2>
                <Button color="primary" onClick={toggleModal} className="rotate-button" style={{ borderRadius: '30px', padding: '6px 14px' }}>Add Lead</Button>
            </div>
            <Table striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Added By</th>
                        <th>Added On</th>
                    </tr>
                </thead>
                <tbody>
                    {leads.map(lead => (
                        <tr key={lead._id} onClick={() => handleLeadClick(lead._id)} style={{ cursor: 'pointer' }}>
                            <td><Button color="link">{lead.name}</Button></td>
                            <td>{lead.addedBy}</td>
                            <td>{lead.addedOn.split('T')[0]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add Lead Modal */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add New Lead</ModalHeader>
                <ModalBody>
                    <input
                        type="text"
                        placeholder="Lead Name"
                        name="name"
                        value={newLead.name}
                        onChange={handleInputChange}
                        className="form-control mb-3"
                    />
                    <input
                        type="text"
                        placeholder="Location"
                        name="location"
                        value={newLead.location}
                        onChange={handleInputChange}
                        className="form-control mb-3"
                    />

                    <h5>Contacts</h5>
                    <div className="mb-3">
                        {newLead.contactInfo.map((contact, index) => (
                            <div key={index} className="mb-2 p-2 border rounded">
                                <strong>Contact {index + 1}</strong>
                                <p>Name: {contact.name}</p>
                                <p>Title: {contact.title}</p>
                                <p>Email: {contact.email}</p>
                                <p>Phone: {contact.phone}</p>
                            </div>
                        ))}
                    </div>

                    <h6>Add New Contact</h6>
                    <input
                        type="text"
                        placeholder="Contact Name"
                        name="name"
                        value={newContact.name}
                        onChange={handleContactChange}
                        className="form-control mb-2"
                    />
                    <input
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={newContact.title}
                        onChange={handleContactChange}
                        className="form-control mb-2"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={newContact.email}
                        onChange={handleContactChange}
                        className="form-control mb-2"
                    />
                    <input
                        type="tel"
                        placeholder="Phone"
                        name="phone"
                        value={newContact.phone}
                        onChange={handleContactChange}
                        className="form-control mb-2"
                    />
                    <Button color="secondary" onClick={addContact} block>Add Contact</Button>

                    {/* Add project fields as before */}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddLead}>Add Lead</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default LeadsPage;
