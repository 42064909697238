import React, { useState } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';
import "../../assets/css/impactSharing.css"; // Your custom CSS file
import WidgetPreview from '../../components/WidgetPreview'; // Import the preview component
import { ToastContainer, toast } from 'react-toastify';

const ImpactSharing = () => {
    const [sharingEnabled, setSharingEnabled] = useState(true);
    const { company } = useOutletContext();
    const [customURL, setCustomURL] = useState(company._id);
    const [selectedEquivalents, setSelectedEquivalents] = useState({
        cars: true,
        flights: true,
        homes: true,
        trees: true,
    });
    const shareUrl = `https://c-mass.co/share-impact/${company._id}`;
    // Function to copy link to clipboard
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            toast.success('Link copied to clipboard!');
        }).catch(err => {
            console.error('Error copying link: ', err);
        });
    };
    const handleToggleSharing = () => setSharingEnabled(!sharingEnabled);
    const handleURLChange = (e) => setCustomURL(e.target.value);
    const handleEquivalentsChange = (equivalent) => {
        setSelectedEquivalents({
            ...selectedEquivalents,
            [equivalent]: !selectedEquivalents[equivalent],
        });
    };

    // Build the equivalents string for the embed code
    const buildEquivalentsString = () => {
        return Object.keys(selectedEquivalents)
            .filter(equivalent => selectedEquivalents[equivalent])
            .join(',');
    };

    return (
        <Container className="dashboard">
            {/* Section 1: Public Impact Page */}
            <section className="public-impact-page mb-5">
                <h2>Share your impact</h2>
                <div className="section-card p-4">
                    <h4>Public impact page</h4>
                    <Row>
                        <Col md="3">
                            {/*<FormGroup>
                                <Label for="enable-sharing">Enable sharing</Label>
                                <div className="d-flex align-items-center">
                                    <Input 
                                        type="checkbox"
                                        checked={sharingEnabled}
                                        onChange={handleToggleSharing}
                                        className="ml-2"
                                    />
                                </div>
                            </FormGroup>*/}
                        </Col>
                        <Col md="9">
                            <FormGroup>
                                <Label>Your impact page URL</Label>
                                <div className="d-flex align-items-center">
                                    <Input 
                                        type="text" 
                                        disabled 
                                        value="https://c-mass.co/share-impact/" 
                                        style={{ maxWidth: '200px', marginRight: '10px' }}
                                    />
                                    <Input 
                                        type="text" 
                                        disabled
                                        value={customURL} 
                                        onChange={handleURLChange} 
                                        style={{ maxWidth: '300px', marginRight: '10px' }}
                                    />
                                    <Button onClick={copyLinkToClipboard} color="secondary" outline style={{ borderRadius: '25px' }}>Copy</Button>
                                    <Button color="link" className="ml-3">
                                        <i className="bi bi-box-arrow-up-right"></i> Visit page
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* Section 2: Embeddable Widget */}
            <section className="embeddable-widget">
                <h4>Embeddable widget</h4>
                <div className="section-card p-4">
                    <p>
                        The Climate Impact Widget can be easily embedded in your website to share your climate impact. 
                        Just copy the snippet below and paste it where you want it to appear.
                    </p>
                    <p>Choose which emissions comparisons you'd like to display on your widget.</p>
                    <div className="d-flex flex-wrap mb-3">
                        <FormGroup check className="mr-3">
                            <Label check style={{marginRight: '10px'}}>
                                <Input 
                                    type="checkbox" 
                                    checked={selectedEquivalents.cars} 
                                    onChange={() => handleEquivalentsChange('cars')} 
                                />{' '}
                                Cars off the road for a year
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mr-3">
                            <Label check style={{marginRight: '10px'}}>
                                <Input 
                                    type="checkbox" 
                                    checked={selectedEquivalents.flights} 
                                    onChange={() => handleEquivalentsChange('flights')} 
                                />{' '}
                                Flights from Riyadh to London
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mr-3">
                            <Label check style={{marginRight: '10px'}}>
                                <Input 
                                    type="checkbox" 
                                    checked={selectedEquivalents.homes} 
                                    onChange={() => handleEquivalentsChange('homes')} 
                                />{' '}
                                Homes' annual energy usage
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input 
                                    type="checkbox" 
                                    checked={selectedEquivalents.trees} 
                                    onChange={() => handleEquivalentsChange('trees')} 
                                />{' '}
                                New trees planted
                            </Label>
                        </FormGroup>
                    </div>

                    {/* Embed Code */}
                    <pre className="embed-code">
{`<div class="cmass-impact-embed"
    data-cmass-impact-slug="${customURL}"
    data-cmass-offset-equivalents="${buildEquivalentsString()}">
</div>
<script type="text/javascript" src="https://public-server-n3bj.onrender.com/scripts/embed.min.js"></script>`}
                    </pre>

                    <h5>Preview</h5>
                    {/* Include WidgetPreview component here */}
                    <WidgetPreview customURL={customURL} equivalents={selectedEquivalents} id={company.orders[company.orders.length-1]}/>
                </div>
            </section>
            <ToastContainer />
        </Container>
    );
};

export default ImpactSharing;
